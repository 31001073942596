var render = function render() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "div",
    {
      staticClass: "fixed-table-div",
    },
    [
      _vm.data
        ? _c("table", [
            _c("thead", [
              _c(
                "tr",
                [
                  _c("th"),
                  _vm._l(_vm.topHeaders, function (header, index) {
                    return _c(
                      "th",
                      {
                        key: index,
                      },
                      [
                        _vm._t("topHeader", null, {
                          data: header,
                        }),
                      ],
                      2
                    )
                  }),
                ],
                2
              ),
            ]),
            _c(
              "tbody",
              _vm._l(_vm.leftHeaders, function (header, leftHeaderIndex) {
                return _c(
                  "tr",
                  {
                    key: leftHeaderIndex,
                  },
                  [
                    _c(
                      "td",
                      [
                        _vm._t("leftHeader", null, {
                          data: header,
                        }),
                      ],
                      2
                    ),
                    _vm._l(_vm.rowLength, function (row, rowIndex) {
                      return _c(
                        "td",
                        {
                          key: rowIndex,
                        },
                        [
                          _vm.selfRender
                            ? [
                                _vm._t("body", null, {
                                  colIndex: rowIndex,
                                  rowIndex: leftHeaderIndex,
                                }),
                              ]
                            : [
                                _vm._t("body", null, {
                                  data: _vm.data[rowIndex][leftHeaderIndex],
                                }),
                              ],
                        ],
                        2
                      )
                    }),
                  ],
                  2
                )
              }),
              0
            ),
          ])
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }